@font-face {
  font-family: "pokemonFont";
  src: local("pokemonFont"),
    url("./resources/fonts/英文神奇寶貝字體.woff2") format("woff2");
  font-weight: normal;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar{
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 13px !important;
  text-align: left !important;
}


.img-fluid{
  display: block !important;
  align-self: center !important;
  
  margin-left: auto !important;
  margin-right: auto !important;
}

.img-fluid.h{
  max-height: 300px !important;
  width: auto !important;
}


.energy{
  max-height: 25px !important;
  width: auto !important;
}

.input-group input{
  box-shadow: none im !important;
  margin-top: 10px !important;
  border: 1px solid black !important;
}

.navbtn:active{
  box-shadow: none !important;
  outline: 0 !important;
}

main {
  
  display: flex;
  flex-wrap: nowrap;
  height: -webkit-fill-available;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: cadetblue;
  bottom: 100px;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}

.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}