.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .switch-field {
    display: flex;
    margin-bottom: 36px;
    overflow: hidden;
  }
  
  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
  
  .switch-field label {
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    width:99%;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }
  
  .switch-field label:hover {
    cursor: pointer;
  }
  
  .switch-field input:checked + label {
    background-color: #a5dc86;
    box-shadow: none;
  }
  
  .switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  
  .switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  .css-19kzrtu {
    padding: 12px !important;
  }

  .datePicker{
    padding: 8px;
  }
  .datePicker fieldset{
    top: 0px !important;
  }

  .datePicker label{
    background-color: white !important;
    margin-top: 0.08rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  