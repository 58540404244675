.alert-container {
    position: absolute;
    z-index: 1;
    top: 4rem;
    left: 0;
    right: 0;
  }
  
  .alert-inner {
    display: inline-block;
    width:100%;
    padding: 8px 16px;
    z-index: 1;
    box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
    -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
    -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
    text-align: center;
  }